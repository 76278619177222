import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { baseFetch } from '../../config';

const medicationLoader = async (page = 1, episodeId) => {
  try {
    let userData = JSON.parse(localStorage.getItem('userInfo'));
// const enpoint= 'api/health/patient/medication?patientId=684297&mrn=704530&page=1&episodeId='
    const endpoint = `api/health/patient/medication?patientId=${userData?.patientId}&mrn=${userData?.mrn}&page=${page}&episodeId=${episodeId}`;

    const data = await baseFetch(endpoint, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
      },
    });

    return data;
  } catch (error) {
    console.error('Error on Medication', error);
    throw error;
  }
};

function useFetchMedicationsByEpisode() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const hospitalId = useSelector((state) => state.hospitalInfo.hospId);
  const { selectedManageAppointment } = useSelector(
    (state) => state.appointments
  );

  const fetchData = async () => {
    if (loading || !hasMore) return; // Prevent fetching if already loading or no more data
    if (page < 2) {
      setLoading(true);
    }

    setError(null); // Reset error state before fetching new data

    try {
      const apiResponse = await medicationLoader(
        page,
        selectedManageAppointment?.id
      ); // Include hospitalId if needed in the API call
      const listData = apiResponse?.medication_list || [];

      if (listData.length === 0) {
        setHasMore(false); // No more data to fetch
      } else {
        setData((prevData) => [...prevData, ...listData]);
        // setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      setError(error);
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedManageAppointment?.id) {
      fetchData();
    }
  }, [hospitalId, page, selectedManageAppointment]);

  return { data, loading, error, refetch: fetchData };
}

export default useFetchMedicationsByEpisode;
