
import React from 'react';
import { useSelector } from 'react-redux';
import PrescriptionItem from './PrescriptionItem';
import { selectLanguage } from '../../../../store/languageSlice';
import useFetchPrescriptionsByEpisode from '../../../../utilFunctions/completedAppointment/useFetchPrescriptionsByEpisode';
import CollapsibleWrapper from '../../CollapsibleWrapper';
import Loader from '../../../UI/Loader';

const Prescriptions = () => {
  const { data, loading, error } = useFetchPrescriptionsByEpisode();
  const { t, isRtl } = useSelector(selectLanguage);
  console.log('prescription data', data);

  return (
    <CollapsibleWrapper title="Prescriptions">
      <div className="flex flex-col">
        <ul>
          {data?.map((item, index) => {
            return (
              <li key={index} className="mb-2">
                <PrescriptionItem index={index} item={item} />
              </li>
            );
          })}
        </ul>

        {loading && (
          <div className="w-full justify-center">
            <Loader roundLoader />
          </div>
        )}
        {!loading && data?.length === 0 && (
          <div className="w-full flex justify-center">
            <span className="mt-8">{t.NoRecord}</span>
          </div>
        )}
      </div>
    </CollapsibleWrapper>
  );
};

export default Prescriptions;
