import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { envVariables } from '../../../config';
import { selectLanguage } from '../../../store/languageSlice';
// import { FiClock } from "react-icons/fi";
import { TbCalendarTime } from 'react-icons/tb';
import { getTValue } from '../../../translations/common';
import { updateSelectManageAppontemnt } from '../../../store/appointmentSlice';

const SingleAppointment = ({ appointment, index }) => {
  const { t, isRtl } = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const { assetsRoute } = envVariables;
  const hospCode = useSelector((state) => state.hospitalInfo.hospCode);
  const { selectedManageAppointment } = useSelector(
    (state) => state.appointments
  );
  const locale = isRtl ? 'ar' : 'en-US';
  const isCompleted = appointment?.bookingStatus === 4;

  const handleOnClick = () => {
    if (!isCompleted) return;
    if (selectedManageAppointment?.id === appointment?.id) {
      dispatch(updateSelectManageAppontemnt(null));
    } else {
      dispatch(updateSelectManageAppontemnt({...appointment, date: appointment?.date?.toISOString()}));
    }
  };

  return (
    <li
      className={`${
        selectedManageAppointment?.id === appointment?.id ? 'selectedCard p-2' : ''
      } ${isCompleted ? 'cursor-pointer' : ''}`}
      onClick={handleOnClick}
    >
      <div className={`docInfo`}>
        {appointment.docCode ? (
          <img
            src={`${assetsRoute}${hospCode}/${appointment.docCode}.jpg`}
            onError={(e) => {
              if (
                e.target.src !==
                `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`
              ) {
                e.target.src = `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`;
              }
            }}
            alt={appointment.careProviderDescription}
          />
        ) : (
          <img
            src={`${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`}
            alt={appointment.careProviderDescription}
          />
        )}
        <div className="info">
          <h5>
            {getTValue(
              appointment,
              'arabicProviderDescription',
              'careProviderDescription',
              isRtl
            )}
          </h5>
          <p>
            {getTValue(
              appointment,
              'arabicServiceDescription',
              'serviceDescription',
              isRtl
            )}
          </p>
        </div>
      </div>
      <div className="aptInfo">
        <p>
          <TbCalendarTime /> {appointment.date.getDate()}{' '}
          {appointment.date.toLocaleString(locale, { month: 'short' })} at{' '}
          {
            appointment.date
              .toLocaleString('en-US', { hour: 'numeric', hour12: true })
              .split(' ')[0]
          }{' '}
          : {appointment.date.getMinutes().toString().padStart(2, '0')}{' '}
          {
            appointment.date
              .toLocaleString('en-US', { hour: 'numeric', hour12: true })
              .split(' ')[1]
          }
        </p>
        <span
          className="status"
          style={{
            '--status-color':
              appointment.bookingStatus === 4
                ? 'var(--color-green)'
                : appointment.bookingStatus === 3
                ? 'var(--color-tertiary)'
                : 'var(--color-muted)',
          }}
        >
          {appointment.bookingStatus === 4
            ? t['Completed']
            : appointment.bookingStatus === 3
            ? t['Cancelled']
            : t['No_Show']}
        </span>
      </div>
      {/* <span className="status" style={{"--status-color": appointment.bookingStatus === 4 ? "var(--color-secondary)" : appointment.bookingStatus === 3 ? "var(--color-tertiary)" : "#414D55"}}>{appointment.bookingStatus === 4 ? t["Completed"] : appointment.bookingStatus === 3 ? t["Cancelled"] : t["No_Show"] }</span>
            <div className="dateTime">
                <div className="date">
                    {appointment.date.getDate()}
                    <span>{appointment.date.toLocaleString(locale, { month: 'short' })}</span>
                </div>
                <div dir="ltr" className="time">
                    {appointment.date.toLocaleString('en-US', { hour: 'numeric', hour12: true }).split(' ')[0]} : {appointment.date.getMinutes().toString().padStart(2, '0')}
                    <span>{appointment.date.toLocaleString('en-US', { hour: 'numeric', hour12: true }).split(' ')[1]}</span>
                </div>
            </div>
            <div className="docInfo">
                <div className="info">
                    <h5>{isRtl ? appointment.arabicProviderDescription :  appointment.careProviderDescription}</h5>
                    <p>{isRtl ? appointment.arabicServiceDescription : appointment.serviceDescription}</p>
                </div>
                {
                    appointment.docCode ? 
                    <img src={`${assetsRoute}${hospCode}/${appointment.docCode}.jpg`}  onError={(e)=>{ if (e.target.src !== `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`){e.target.src=`${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`;}}} alt={appointment.careProviderDescription} />
                    :
                    <img src={`${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`} alt={appointment.careProviderDescription} />
                }
            </div> */}
    </li>
  );
};

export default SingleAppointment;
