import moment from 'moment';
import { formatDate } from '../translations/common';
export const DATE_FORMAT = 'YYYY-MM-DD'; // 2023-01-01
export const HEADER_DATE_FORMAT = 'MMM-YY'; // Jan-23

export const VITALS_SIGNS_ICONS = {
  HEIGHT: `rular.svg`,
  WEIGHT: `bmi.svg`,
  EWS00: `bmi.svg`,
  VS1: `pulse.svg`,
  VS2: `pluse.svg`,
  VS3: `pluse.svg`,
  VS4: `pluse.svg`,
  VS5: `pluse.svg`,
  MEXXICUSPO2: `bmi.svg`,
  VSMAP: `pluse.svg`,
  'TC.NO01': `bmi.svg`,
};

export const VITALS_REPORT = {
  vital_list: [
    {
      id: '2480899||1',
      episode_number: 'OP0002140905',
      observation_item_code: 'VS1',
      observation_item_description: 'Systolic',
      observation_date: '2024-07-16',
      observation_time: '12:03',
      value: '123',
      uom: 'mmHg',
    },
    {
      id: '2480899||2',
      episode_number: 'OP0002140905',
      observation_item_code: 'VS2',
      observation_item_description: 'Diastolic',
      observation_date: '2024-08-17',
      observation_time: '12:03',
      value: '85',
      uom: 'mmHg',
    },
    {
      id: '2480899||3',
      episode_number: 'OP0002140905',
      observation_item_code: 'VS3',
      observation_item_description: 'Pulse',
      observation_date: '2024-07-18',
      observation_time: '12:03',
      value: '98',
      uom: 'bt/min',
    },
    {
      id: '2480899||3',
      episode_number: 'OP0002140905',
      observation_item_code: 'VS3',
      observation_item_description: 'Pulse',
      observation_date: '2024-07-19',
      observation_time: '12:03',
      value: '98',
      uom: 'bt/min',
    },
    {
      id: '2480899||3',
      episode_number: 'OP0002140905',
      observation_item_code: 'VS3',
      observation_item_description: 'Pulse',
      observation_date: '2024-07-20',
      observation_time: '12:03',
      value: '98',
      uom: 'bt/min',
    },
    {
      id: '2480899||4',
      episode_number: 'OP0002140905',
      observation_item_code: 'VS4',
      observation_item_description: 'Respiratory Rate',
      observation_date: '2023-07-16',
      observation_time: '12:03',
      value: '18',
      uom: 'br/min',
    },
    {
      id: '2480899||5',
      episode_number: 'OP0002140905',
      observation_item_code: 'VS5',
      observation_item_description: 'Temperature',
      observation_date: '2023-07-17',
      observation_time: '12:03',
      value: '37.5',
      uom: '°C',
    },
    {
      id: '2480899||6',
      episode_number: 'OP0002140905',
      observation_item_code: 'MEXXICUSPO2',
      observation_item_description: 'SPO2',
      observation_date: '2024-07-16',
      observation_time: '12:03',
      value: '100',
      uom: '%',
    },
    {
      id: '2480899||7',
      episode_number: 'OP0002140905',
      observation_item_code: 'VSMAP',
      observation_item_description:
        'Mean Arterial Pressure, Non-invasive (Calculated)',
      observation_date: '2024-07-16',
      observation_time: '12:03',
      value: '98',
      uom: 'mmHg',
    },
    {
      id: '2480899||8',
      episode_number: 'OP0002140905',
      observation_item_code: 'HEIGHT',
      observation_item_description: 'Height (Ht)',
      observation_date: '2024-07-16',
      observation_time: '12:03',
      value: '177',
      uom: 'cm',
    },
    {
      id: '2480899||9',
      episode_number: 'OP0002140905',
      observation_item_code: 'WEIGHT',
      observation_item_description: 'Weight',
      observation_date: '2024-07-16',
      observation_time: '12:03',
      value: '83',
      uom: 'kg',
    },
    {
      id: '2480899||10',
      episode_number: 'OP0002140905',
      observation_item_code: 'TC.NO01',
      observation_item_description: 'Eye Opening',
      observation_date: '2022-07-16',
      observation_time: '12:03',
      value: '4',
      uom: 'NULL',
    },
  ],
  item_count: 138,
  page: 1,
  total: 14,
  status: 'Success',
};

export const headerDateFormater = (date, isRtl) => {
  const cDate = formatDate(date, isRtl);
  return `${cDate.monthDate} ${cDate.monthNameShort} ${cDate.year}`;
  // return `${cDate.monthDate} ${cDate.monthNameShort}`
};

export const getYearFromDate = (dateValue) => {
  const date = moment(dateValue);
  const year = date.year();
  return year;
};

export const getfilterYears = () => {
  const currentYear = moment().year();
  const yearsArray = [];

  for (let year = 2022; year <= currentYear; year++) {
    yearsArray.push({
      label: year,
      value: year,
    });
  }

  return yearsArray.reverse();
};

export const getYearsFromData = (list) => {
  const years = list.map((item) =>
    new Date(item.observation_date).getFullYear()
  );
  const uniqeYears = [...new Set(years)];

  return uniqeYears.map((x) => {
    return { label: x, value: x };
  });
};

export const getIconByCode = (code) => {
  const basePath = `${process.env.PUBLIC_URL}/images/icons`;
  const icons = {
    HEIGHT: `${basePath}/vs-height.svg`,
    WEIGHT: `${basePath}/vs-weight.svg`,
    EWS00: `${basePath}/vs-ews-total.svg`,
    BMI: `${basePath}/vs-bmi.svg`,
    VS1: `${basePath}/vs-systolic.svg`,
    VS2: `${basePath}/vs-diastolic.svg`,
    VS3: `${basePath}/vs-pulse.svg`,
    VS4: `${basePath}/vs-resp-rate.svg`,
    VS5: `${basePath}/vs-temp.svg`,
    MEXXICUSPO2: `${basePath}/vs-spo2.svg`,
    VSMAP: `${basePath}/vs-mean-ap.svg`,
    'TC.NO01': `${basePath}/vs-eye.svg`,
    'TC.NO02': `${basePath}/vs-verbal.svg`,
    'TC.NO03': `${basePath}/vs-motor.svg`,
    'TC.NO04': `${basePath}/vs-glasco-coma.svg`,
    'TC.BSA': `${basePath}/vs-body-surface.svg`,
    'MEXX.IBW': `${basePath}/vs-ideal-body-weight.svg`,
    'TC.B': `${basePath}/vs-behaviour.svg`,
    'TC.PCA4': `${basePath}/vs-pain-scale.svg`,
  };

  const defaultIcon = `${basePath}/vs-pulse.svg`;

  return icons[code] ?? defaultIcon;
};

export const getMostRecntVitalSigns1 = (data, isRtl) => {
  if (!data && !Array.isArray(data)) return [];

  // Get unique items by observation_item_code with most recent observation_date
  const uniqueMostRecentItems = Object.values(
    data.reduce((acc, item) => {
      if (
        !acc[item.observation_item_code] ||
        moment(item.observation_date).isAfter(
          moment(acc[item.observation_item_code].observation_date)
        )
      ) {
        acc[item.observation_item_code] = item;
      }
      return acc;
    }, {})
  );

  const cardsData = uniqueMostRecentItems?.map((x, index) => {
    return {
      title: x?.observation_item_description || '-',
      icon: getIconByCode(x.observation_item_code),
      value: x?.value || '-',
      unit: x?.uom || '-',
      difference: `${'0.3'} ${x.uom ?? '-'}`,
      isShow: index < 4 ? true : false,
      isActive: false,
    };
  });

  return cardsData;
};

export const getMostRecntVitalSigns2 = (data, isRtl) => {
  if (!data || !Array.isArray(data)) return [];

  // Track both the most recent and second most recent items by observation_item_code
  const mostRecentItems = {};
  const secondMostRecentItems = {};

  data.forEach((item) => {
    const code = item.observation_item_code;

    if (!mostRecentItems[code]) {
      // If there's no entry for this code, this is the most recent one
      mostRecentItems[code] = item;
    } else if (
      moment(item.observation_date).isAfter(
        moment(mostRecentItems[code].observation_date)
      )
    ) {
      // If this item is more recent, the current most recent becomes the second most recent
      secondMostRecentItems[code] = mostRecentItems[code];
      mostRecentItems[code] = item;
    } else if (
      !secondMostRecentItems[code] ||
      moment(item.observation_date).isAfter(
        moment(secondMostRecentItems[code].observation_date)
      )
    ) {
      // If this item is not more recent than the most recent, check if it's more recent than the second most recent
      secondMostRecentItems[code] = item;
    }
  });

  // Create the card data with differences
  const cardsData = Object.values(mostRecentItems)?.map((x, index) => {
    const prevValue =
      secondMostRecentItems[x.observation_item_code]?.value || null;
    const currentValue = parseFloat(x.value);
    const differenceInDigit =
      prevValue !== null ? currentValue - parseFloat(prevValue) : 0;

    const diff = `${
      differenceInDigit < 0
        ? `<${(differenceInDigit * -1).toFixed(2)}`
        : `>${differenceInDigit.toFixed(2)}`
    } ${x?.uom ?? '-'}`;

    return {
      title: x?.observation_item_description || '-',
      icon: getIconByCode(x.observation_item_code),
      value: currentValue || 0,
      unit: x?.uom || '-',
      difference: diff,
      isShow: index < 4 ? true : false,
      isActive: false,
      showDiff: differenceInDigit !== 0,
    };
  });

  return cardsData;
};

export const getMostRecntVitalSigns = (data, isRtl) => {
  if (!data && !Array.isArray(data)) return [];
  const years = getYearsFromData(data);
  // Get unique items by observation_item_code with most recent observation_date
  const { rowData } = formatData(data, years[0]?.value || 2024, isRtl);

  const cardsData = rowData?.map((x, index) => {
    const { description, code, date0, date1, uom } = x;
    const currentValue = parseFloat(date0) || 0;
    const differenceInDigit =
      date1 !== null && date1 != '-' ? currentValue - parseFloat(date1) : 0;
    const unit = uom && uom.toLowerCase() != 'null' ? `${uom}` : '';
    const diff = `${
      differenceInDigit < 0
        ? `<${(differenceInDigit * -1).toFixed(2)}`
        : `>${differenceInDigit.toFixed(2)}`
    } ${unit}`;

    return {
      title: description || '-',
      icon: getIconByCode(code),
      value: date0 || '-',
      unit: unit,
      difference: diff,
      isShow: index < 4 ? true : false,
      isActive: false,
      showDiff: differenceInDigit != 0 && !diff.includes('NaN'),
    };
  });

  return cardsData;
};

export const formatData = (data, selectedYear, isRtl) => {
  if (!data && !Array.isArray(data)) return { columnDefs: [], rowData: [] };

  const uniqueVital = [
    ...new Map(data.map((item) => [item.observation_item_code, item])).values(),
  ];

  const uniqueCodes = Array.from(
    new Set(data.map((item) => item.observation_item_code))
  );

  const uniqueDates11 = Array.from(
    new Set(data.map((item) => item.observation_date))
  );

  const uniqueDates = uniqueDates11.filter(
    (x) => getYearFromDate(x) === selectedYear
  );

  const columnDefs = [
    {
      field: 'Vital_Signs',
      headerName: isRtl ? 'العلامات الحيوية' : 'Vital Signs',
      cellRenderer: 'vitalSignsRenderer',
      // pinned: 'left',
    },
    ...uniqueDates.map((date, index) => ({
      headerName: headerDateFormater(date, isRtl),
      field: 'date' + index,
      minWidth: 100,
      valueFormatter: (params) => {
        const value = params.value;
        // Check if value is a number
        const fomatedValue = value
          ? !isNaN(parseFloat(value))
            ? parseFloat(value)
            : value
          : '-';
        return fomatedValue; // Return strings as they are
      },
    })),
  ];

  // Helper function to create rowData for each observation item
  const createRowDataForDate = (itemCode, dates, data) => {
    const rowData = {};
    dates.forEach((date, index) => {
      const matchingItem = data.find(
        (item) =>
          item.observation_date === date &&
          item.observation_item_code === itemCode
      );
      rowData['date' + index] = matchingItem ? matchingItem.value : '-';
    });

    return rowData;
  };

  // Create rowData array
  const rowData1 = [
    {
      Vital_Signs: 'Height',
      ...createRowDataForDate('HEIGHT', uniqueDates, data),
    },
    {
      Vital_Signs: 'Weight',
      ...createRowDataForDate('WEIGHT', uniqueDates, data),
    },
    {
      Vital_Signs: 'EWS Total',
      ...createRowDataForDate('EWS00', uniqueDates, data),
    },
  ];

  const rowData = uniqueVital.map((x) => {
    const { observation_item_code, observation_item_description, uom } = x;
    const unit = uom && uom.toLowerCase() != 'null' ? `(${uom})` : '';

    return {
      Vital_Signs: `${observation_item_description} ${unit}`,
      // Vital_Signs: `${observation_item_description} (${observation_item_code})`,
      description: observation_item_description,
      code: observation_item_code,
      uom,
      ...createRowDataForDate(observation_item_code, uniqueDates, data),
    };
  });

  const uniqeCodeWithNames = uniqueVital.map((x) => {
    return {
      vitalName: x.observation_item_description,
      code: x.observation_item_code,
    };
  });

  const formatedData = { columnDefs, rowData };

  return formatedData;
};

const fomatedVitalValue = (value) => {
  return value ? (!isNaN(parseFloat(value)) ? parseFloat(value) : value) : '-';
};

export const formatVitalData = (vitalList) => {
  // Group data by observation_item_code
  if(!vitalList || !Array.isArray(vitalList)) return [];

  const groupedData = vitalList.reduce((acc, item) => {
    const { observation_item_code } = item;
    if (!acc[observation_item_code]) {
      acc[observation_item_code] = [];
    }
    acc[observation_item_code].push(item);
    return acc;
  }, {});

  // Prepare the formatted data for ApexCharts
  const formattedData = Object.values(groupedData).map((group) => {
    // Sort each group by observation_date and observation_time in descending order
    group.sort((a, b) => {
      const dateA = moment(`${a.observation_date} ${a.observation_time}`, 'YYYY-MM-DD HH:mm');
      const dateB = moment(`${b.observation_date} ${b.observation_time}`, 'YYYY-MM-DD HH:mm');
      return dateB.diff(dateA); // Sort in descending order to get most recent first
    });

    // Take the most recent item in the sorted group
    const mostRecent = group[0];
    const uom = mostRecent?.uom && mostRecent?.uom.toLowerCase() != 'null' ? `(${mostRecent?.uom})` : '';
    // Create series for ApexCharts
    return {
      code: mostRecent.observation_item_code,
      name: mostRecent.observation_item_description,
      value: fomatedVitalValue(mostRecent.value),
      uom,
      data: group.map((item) => ({
        x: item.observation_date,
        y: parseFloat(item.value),
      })),
    };
  });

  return formattedData;
};
